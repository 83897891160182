import './style.css';
import {Map, View} from 'ol';
import {fromLonLat} from 'ol/proj';
import {ScaleLine} from 'ol/control';
import Overlay from 'ol/Overlay';
import Geolocation from 'ol/Geolocation';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';

import LayerSwitcher from 'ol-layerswitcher';

// ---------------------------------------------------------------------
// text snippets

var att_geodaten_mv = '&copy; GeoBasis DE M-V'
    + ' <a href="http://www.geodaten-mv.de">geodaten-mv.de</a>.&nbsp;'

var att_orka_mv = '&copy; Hansestadt Rostock'
    + ' <a href="http://www.orka-mv.de">orka-mv.de</a>.&nbsp;'

// ---------------------------------------------------------------------
// base layers

var osm = new TileLayer({
    title: 'OpenStreetMap',
    type: 'base',
    visible: false,
    source: new OSM()
});

var mv_orka = new TileLayer({
    title: 'Offene Regionalkarte MV',
    type: 'base',
    visible: true,
    source: new TileWMS({
	url: 'https://www.orka-mv.de/geodienste/orkamv/wms?',
	params: {
            'LAYERS': 'orkamv',
	},
	attributions: att_orka_mv,
    })
});

var mv_topo = new ImageLayer({
    title: 'Topografische Karte MV',
    type: 'base',
    visible: false,
    source: new ImageWMS({
	url: 'https://www.geodaten-mv.de/dienste/gdimv_dtk?language=ger',
	params: {
            'LAYERS': 'WMS_MV_DTK',
	},
	attributions: att_geodaten_mv,
    })
});

var mv_dop = new ImageLayer({
    title: 'Luftbilder MV',
    type: 'base',
    visible: false,
    source: new ImageWMS({
	url: 'https://www.geodaten-mv.de/dienste/adv_dop?language=ger',
	params: {
            'LAYERS': 'mv_dop',
	},
	attributions: att_geodaten_mv,
    })
});

// ---------------------------------------------------------------------
// overlay layers

var mv_flurgrenzen = new ImageLayer({
    title: 'Flurgrenzen MV',
    visible: false,
    source: new ImageWMS({
	url: 'https://www.geodaten-mv.de/dienste/dfg_wms?',
	params: {
            'LAYERS' : 'gema,flur',
	},
	attributions: att_geodaten_mv,
    })
});

var mv_flurkarte = new ImageLayer({
    title: 'Flurkarte MV',
    visible: false,
    source: new ImageWMS({
	url: 'https://www.geodaten-mv.de/dienste/alkis_wms?',
	params: {
            'LAYERS' : 'adv_alkis_flurstuecke',
	},
	attributions: att_geodaten_mv,
    })
});

var mv_gebaeude = new TileLayer({
    title: 'Gebäude MV',
    visible: false,
    source: new TileWMS({
	url: 'https://www.geodaten-mv.de/dienste/alkis_wms?',
	params: {
            'LAYERS' : 'adv_alkis_gebaeude',
	},
	attributions: att_geodaten_mv,
    })
});

// ---------------------------------------------------------------------
// start views

var view_carwitz_kirche  = new View({
    center: fromLonLat([13.4398, 53.3021]),
    zoom: 15
})

var view_feldberg_kirche  = new View({
    center: fromLonLat([13.4369, 53.3353]),
    zoom: 14
})

var view_start = view_feldberg_kirche

// ---------------------------------------------------------------------
// map

const map = new Map({
    target: 'map',
    layers: [
        // base
        osm,
        mv_orka,
        mv_topo,
        mv_dop,
        // overlay
        mv_flurgrenzen,
        mv_flurkarte,
        mv_gebaeude
    ],
    view: view_start
});

var switcher = new LayerSwitcher({});
map.addControl(switcher);

var scaleline = new ScaleLine({});
map.addControl(scaleline);

// ------------------------------------------------------------------------
// track current position

var marker = new Overlay({
    element: document.getElementById('location'),
    positioning: 'bottom-center',
    stopEvent: false,
});
marker.setPosition(fromLonLat([13.4369, 53.3353]));
map.addOverlay(marker);

var geolocation = new Geolocation({
    projection: view_start.getProjection(),
    tracking: true,
});

geolocation.on('change:position', function() {
    var p = geolocation.getPosition();
    marker.setPosition(p);
});
